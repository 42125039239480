import { LoadingIndicator } from '~/components/base/loadingIndicator'

export function Button({
  variant = 'primary',
  type = 'button',
  isLoading = false,
  disabled = false,
  className = '',
  onClick,
  name,
  value,
  children,
}) {
  return (
    <button
      type={type}
      className={`justify-center rounded-md border shadow-sm px-4 py-2 text-sm disabled:opacity-50 disabled:cursor-not-allowed flex ${
        variant === 'secondary'
          ? 'border-gray-300 bg-white text-kalirteal-dark disabled:text-gray-500'
          : 'border-transparent bg-kalirteal text-white'
      } ${className}`}
      name={name}
      value={value}
      disabled={isLoading || disabled ? true : null}
      autoComplete="off"
      onClick={onClick}
    >
      {isLoading && <LoadingIndicator variant={variant} className="mr-2" />}
      {children}
    </button>
  )
}
